import dynamic from 'next/dynamic'
import SectionLayout from '@components/ui/SectionLayout'
import {
  getComponentData,
  isObjEmpty,
  keysSnakeToCamel,
} from '@components/helper'
import Pop from 'templates/Pages/PPSDeals/components/Banner/Pop'

const CountDown = dynamic(() => import('./common/CountDown/CountDown'))
const ListingBanner = dynamic(() => import('@components/banner/ListingBanner'))
const AmazonTiles = dynamic(() => import('./tile/AmazonTiles'))
const YoutubeSlider = dynamic(() => import('@components/slider/youtubeSlider'))
const FullWidthSlider = dynamic(() =>
  import('@components/slider/FullWidthSlider')
)
const Subscription = dynamic(() =>
  import('@components/common/subscription/Subscription')
)
const SubscriptionBar = dynamic(() =>
  import('@components/common/SubscriptionBar/SubscriptionBar')
)
const Multihouse = dynamic(() =>
  import('@components/product/multihouse/Multihouse')
)
const CollectionsSwiper = dynamic(() =>
  import('@components/product/CollectionsSwiper/CollectionsSwiper')
)

const StaggeredTiles = dynamic(() => import('@components/tile/StaggeredTiles'))
const BasicTiles = dynamic(() => import('@components/tile/BasicTiles'))
const TabsInRight = dynamic(() => import('@components/tab/TabInRight'))
const SeriesIntro = dynamic(() => import('@components/shop/SeriesIntro'))
const Comparison = dynamic(() =>
  import('@components/product/Comparison/Comparison.js')
)
const Picture = dynamic(() => import('@components/ui/Picture'))
const SingleSlider = dynamic(() => import('@components/slider/SingleSlider'))

const BrandInfo = dynamic(() => import('@components/shop/BrandInfo'))
const Specs = dynamic(() => import('@components/product/ProductManuals/Specs'))
const PowerHouseSpecs = dynamic(() =>
  import('@components/product/PowerHouseSpecs')
)
const Notes = dynamic(() =>
  import('@components/product/ProductManuals/Notes.js')
)
const Documents = dynamic(() =>
  import('@components/product/ProductManuals/Documents')
)
const Banner = dynamic(() => import('@components/banner/Banner'))
const ProductAPlus = dynamic(() => import('@components/product/ProductAPlus'))
const Slider3p = dynamic(() => import('@components/product/Slider3p'))
const Recommends = dynamic(() => import('@components/product/Recommends'))
const FAQ = dynamic(() => import('@components/product/ProductManuals/Faqs'))
const KlarnaBanner = dynamic(() => import('@components/banner/KlarnaBanner'))
const IntroSlider = dynamic(() => import('@components/slider/Slider'))
const Reviews = dynamic(() => import('@components/product/Reviews/Reviews'))
const PopCoupon = dynamic(() => import('@components/product/PopCoupon'))
const AutoDiscountProducts = dynamic(() =>
  import('@components/product/AutoDiscountProducts')
)
const HtmlContainer = dynamic(() =>
  import('@components/common/HtmlContainer/HtmlContainer')
)
const TopDownSwipeBanner = dynamic(() =>
  import('@components/banner/TopDownSwipeBanner')
)
const GreenerFuture = dynamic(() => import('@components/common/GreenerFuture'))
const SwipeableViews = dynamic(() =>
  import('@components/common/SwipeableViews/SwipeableViews')
)
const Tab65w = dynamic(() => import('@components/tab65w/index'))

const Firework = dynamic(() => import('@components/product/Firework/index'))

const Staytuned = dynamic(() => import('@components/product/Staytuned'))

const FlashSaleCharger = dynamic(() =>
  import('@components/product/FlashSaleCharger')
)

const FlashSaleStable = dynamic(() =>
  import('@components/product/FlashSaleStable')
)

const Calculator = dynamic(() =>
  import('templates/Pages/AnkerDay/components/Calculator')
)
const TabsModel = dynamic(() => import('@components/common/tabsModel/index'))

const ImageWithYoutube = dynamic(() =>
  import('@components/common/ImageWithYoutube/index')
)
const Media = dynamic(() => import('@components/home/Media'))

const BaresAni = dynamic(() =>
  import('templates/Pages/A17c1/components/BaresAni/BaresAni')
)
const Speicher = dynamic(() =>
  import('templates/Pages/A17c1/components/Speicher')
)

const EnvironmentProtection = dynamic(() =>
  import('@components/common/EnvironmentProtection')
)

const NewBlogs = dynamic(() => import('@components/common/NewBlogs'))

const SwiperWithCldSwiper = dynamic(() =>
  import('@components/common/SwiperWithCldSwiper')
)

const VoltaicProduct = dynamic(() =>
  import('@components/common/VoltaicProduct')
)

const Blogs = dynamic(() => import('@components/common/Blogs'))

const Ksp = dynamic(() => import('@components/common/Ksp'))

// const Calculator = dynamic(() =>
//   import('templates/Pages/')
// )
import { useRelativeLink } from '@commerce/product/use-relative-link'

const onlyStylesInData = (data) => {
  if (!data) return false
  return Object.keys(data).length === 1 && 'styles' in data
}

const components = ({
  dataKey,
  data,
  shopData,
  product,
  productData,
  metafields,
  relatedProducts,
  pagehandle,
  pageType,
  setRelativeLink,
}) => {
  const mobileFullWidthFalse = {
    responsive: [
      {
        breakpoint: 769,
        settings: {
          fullWidth: data?.styles?.fullWidth === true ? true : false,
        },
      },
    ],
  }

  return {
    banner: {
      jsx: <Banner data={data} id={`banner-${dataKey}`} />,
      fullWidth: true,
      noPadding: true,
    },
    tab65w: {
      jsx: <Tab65w data={data} id={`banner-${dataKey}`} />,
      fullWidth: true,
      noPadding: true,
      ...mobileFullWidthFalse,
    },
    listingBanner: {
      jsx: <ListingBanner data={data} />,
      fullWidth: true,
      noPadding: true,
    },
    amazonTiles: {
      jsx: <AmazonTiles data={data} />,
      ...mobileFullWidthFalse,
    },
    topDownSwipeBanner: {
      jsx: <TopDownSwipeBanner data={data} />,
      fullWidth: true,
      paddingTop: 0,
      paddingBottom: 0,
      className: '!overflow-visible',
      ...mobileFullWidthFalse,
    },
    staggeredTiles: {
      jsx: <StaggeredTiles data={data} />,
      responsive: [
        {
          breakpoint: 769,
          settings: { fullWidth: false },
        },
      ],
    },
    tabInRight: {
      jsx: <TabsInRight data={data} />,
    },
    popCoupon: {
      jsx: <PopCoupon data={data} />,
    },
    productRecommends: {
      jsx: (
        <Recommends
          data={metafields?.copywriting?.productRecommends}
          product={product}
          id={dataKey}
          pagehandle={pagehandle}
          pageType={pageType}
          metafields={metafields}
        />
      ),
      noNeedData: true,
      className: 'overflow-hidden',
    },
    seriesIntroduce: {
      jsx: (
        <SeriesIntro
          data={
            isObjEmpty(data) || onlyStylesInData(data) ? shopData?.series : data
          }
          product={product}
        />
      ),
      dataFromShop: true,
    },
    compare: {
      jsx: (
        <Comparison
          data={data}
          product={product}
          shopCommon={shopData.shopCommon}
        />
      ),
    },
    brandRecommends: {
      jsx: (
        <SingleSlider
          data={
            isObjEmpty(data) || onlyStylesInData(data)
              ? shopData?.brandRecommends
              : data
          }
        />
      ),
      dataFromShop: true,
    },
    brandInfo: {
      jsx: (
        <BrandInfo
          data={
            isObjEmpty(data) || onlyStylesInData(data)
              ? shopData?.brandInfo
              : data
          }
        />
      ),

      dataFromShop: true,
    },
    productSpecs: {
      jsx: <Specs data={data} />,
    },
    powerHouseSpecs: {
      jsx: <PowerHouseSpecs data={data} productPageMode={true} />,
      fullWidth: true,
      noPadding: true,
    },
    productNotes: {
      jsx: <Notes data={data} />,
    },
    productDownloads: {
      jsx: <Documents data={data} />,
    },
    productDownloads: {
      jsx: <Documents data={data} />,
    },
    staytuned: {
      jsx: <Staytuned data={data} />,
    },
    faq: {
      jsx: <FAQ data={data} />,
      ...mobileFullWidthFalse,
    },
    klarnaBanner: {
      jsx: <KlarnaBanner data={data} />,
    },
    introSlider: {
      jsx: (
        <IntroSlider
          data={data}
          arrowClassName="only-in-pc"
          textAbove={
            !data ? null : (
              <>
                <p
                  className="mb-2 text-4xl font-bold"
                  dangerouslySetInnerHTML={{ __html: data.title }}
                ></p>
                <a
                  className="mb-3 cursor-pointer text-sm text-anker-color md:text-[14px]"
                  href={setRelativeLink({ link: data.link })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.link_txt}
                </a>
              </>
            )
          }
          items={
            !data
              ? null
              : data.items?.map((item, index) => (
                  <div className="px-2 md:px-0" key={index}>
                    <Picture
                      className="aspect-h-5 aspect-w-4 w-full"
                      source={`${item.img}`}
                    />
                  </div>
                ))
          }
        />
      ),
    },
    mediaSlider: {
      jsx: (
        <IntroSlider
          data={data}
          dots={false}
          textAbove={
            !data ? null : (
              <>
                <h2
                  className="mb-4 text-5xl font-bold md:text-[1.7rem]"
                  dangerouslySetInnerHTML={{ __html: data.title }}
                ></h2>
                <p
                  className="mb-8 text-2xl md:text-sm"
                  dangerouslySetInnerHTML={{ __html: data.sub_title }}
                ></p>
              </>
            )
          }
          items={
            !data
              ? null
              : data.items?.map((item, index) => (
                  <div
                    className="!inline-flex flex-col items-center px-6 md:px-0 "
                    key={index}
                  >
                    <Picture
                      className="w-full max-w-[360px] md:w-[70%]"
                      imgClassName="object-fit"
                      source={`${item.img_url}`}
                    />
                    <p
                      className="max-w-[360px] pt-4 text-center text-xl font-semibold md:w-[70%]"
                      dangerouslySetInnerHTML={{ __html: item.sub_text }}
                    ></p>
                  </div>
                ))
          }
        />
      ),
    },
    reviews: {
      jsx: <Reviews product={product} data={productData} shopData={shopData} />,
      noNeedData: true,
      noPadding: true,
    },
    autoDiscountProducts: {
      jsx: <AutoDiscountProducts data={data} metafields={metafields} />,
      noNeedData: true,
      fullWidth: true,
      noPadding: true,
    },
    htmlContainer: {
      jsx: <HtmlContainer html={data} />,
    },
    fullHmlContainer: {
      jsx: <HtmlContainer html={data} />,
      fullWidth: true,
      noPadding: true,
    },
    youtubeSlider: {
      jsx: <YoutubeSlider data={data} />,
      fullWidth: data?.items?.length >= 5 ? true : false,
    },
    basicTiles: {
      jsx: <BasicTiles data={data} />,
      ...mobileFullWidthFalse,
    },
    subscription: {
      fullWidth: true,
      noPadding: true,
      jsx: <Subscription data={data} />,
    },
    subscriptionBar: {
      fullWidth: true,
      noPadding: true,
      jsx: <SubscriptionBar data={data} />,
    },
    multihouse: {
      fullWidth: true,
      noPadding: true,
      jsx: <Multihouse data={data} />,
    },
    collectionsSwiper: {
      fullWidth: true,
      noPadding: true,
      jsx: (
        <CollectionsSwiper
          data={data}
          metafields={metafields}
          relatedProducts={relatedProducts}
        />
      ),
    },
    aplusHotspots1: {
      fullWidth: true,
      noPadding: true,
      jsx: (
        <ProductAPlus
          data={data}
          dataKey={dataKey}
          product={product}
          type="aplusHotspots1"
        />
      ),
    },
    aplusNavCarousel: {
      fullWidth: true,
      noPadding: true,
      jsx: (
        <ProductAPlus
          data={data}
          dataKey={dataKey}
          product={product}
          type="aplusNavCarousel"
        />
      ),
    },
    aplusImageCarousel: {
      fullWidth: true,
      noPadding: true,
      jsx: (
        <ProductAPlus
          data={data}
          dataKey={dataKey}
          product={product}
          type="aplusImageCarousel"
        />
      ),
    },
    aplusFullImage: {
      fullWidth: true,
      noPadding: true,
      jsx: (
        <ProductAPlus
          data={data}
          dataKey={dataKey}
          product={product}
          type="aplusFullImage"
        />
      ),
    },
    aplusBgImage: {
      fullWidth: true,
      noPadding: true,
      jsx: (
        <ProductAPlus
          data={data}
          dataKey={dataKey}
          product={product}
          type="aplusBgImage"
        />
      ),
    },
    aplusFullVideo: {
      fullWidth: true,
      noPadding: true,
      jsx: (
        <ProductAPlus
          data={data}
          dataKey={dataKey}
          product={product}
          type="aplusFullVideo"
        />
      ),
    },
    aplusVideoCarousel: {
      fullWidth: true,
      noPadding: true,
      jsx: (
        <ProductAPlus
          data={data}
          dataKey={dataKey}
          product={product}
          type="aplusVideoCarousel"
        />
      ),
    },
    slider3p: {
      noPadding: true,
      jsx: <Slider3p data={data} />,
    },
    greenerFuture: {
      jsx: <GreenerFuture data={data} />,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            paddingBottom: '0px',
          },
        },
      ],
    },
    swipeableViews: {
      jsx: <SwipeableViews data={data} />,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            fullWidth: false,
          },
        },
      ],
    },
    fullWidthSlider: {
      jsx: <FullWidthSlider data={data} />,
      fullWidth: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },
      ],
    },
    countDown: {
      jsx: <CountDown data={data} />,
      fullWidth: true,
      className: '!fixed bottom-0 z-[100] h-[80px] ',
      noPadding: true,
    },
    springSalePop: {
      jsx: (
        <Pop
          data={{
            ...keysSnakeToCamel(shopData.shopCommon),
            ...(data || {}),
          }}
          listing
        />
      ),
      noPadding: true,
      fullWidth: true,
    },
    Firework: {
      jsx: <Firework data={data} />,
      fullWidth: true,
    },
    flashSaleCharger: {
      jsx: (
        <FlashSaleCharger
          data={{
            ...keysSnakeToCamel(shopData.shopCommon),
            ...(data || {}),
          }}
          dataKey={dataKey}
          pageHandle={pagehandle}
        />
      ),
      fullWidth: true,
    },
    flashSaleStable: {
      jsx: (
        <FlashSaleStable
          dataKey={dataKey}
          data={{
            ...keysSnakeToCamel(shopData.shopCommon),
            ...(data || {}),
          }}
          pageHandle={pagehandle}
        />
      ),
      fullWidth: true,
    },
    calculator: {
      jsx: <Calculator data={data} product={product} />,
      noWrap: true,
    },
    tabsModel: {
      jsx: <TabsModel data={data} />,
      ...mobileFullWidthFalse,
    },
    imageWithYoutube: {
      jsx: <ImageWithYoutube data={data} product={product} />,
    },
    baresAni: {
      jsx: <BaresAni data={data} dataKey={dataKey} />,
      fullWidth: true,
      noPadding: true,
    },
    speicher: {
      jsx: <Speicher data={data} dataKey={dataKey} />,
    },
    swiperWithCldSwiper: {
      jsx: (
        <SwiperWithCldSwiper
          data={data}
          dataKey={dataKey}
          pageHandle={pagehandle}
          shopCommon={shopData?.shopCommon}
          metafields={metafields}
          relatedProducts={relatedProducts}
        />
      ),
    },
    voltaicProduct: {
      jsx: (
        <VoltaicProduct
          id={dataKey}
          data={data}
          dataKey={dataKey}
          pageHandle={pagehandle}
          shopCommon={shopData?.shopCommon}
          metafields={metafields}
          relatedProducts={relatedProducts}
        />
      ),
    },
    Blogs: {
      jsx: (
        <Blogs
          data={{
            ...keysSnakeToCamel(shopData.shopCommon),
            ...(data || {}),
          }}
          dataKey={dataKey}
          pageHandle={pagehandle}
          shopCommon={shopData?.shopCommon}
          id={dataKey}
          key={dataKey}
        />
      ),
    },
    ksp: {
      jsx: (
        <Ksp
          data={{
            ...keysSnakeToCamel(shopData.shopCommon),
            ...(data || {}),
          }}
          dataKey={dataKey}
          pageHandle={pagehandle}
          shopCommon={shopData?.shopCommon}
          id={dataKey}
          key={dataKey}
        />
      ),
    },
    environmentProtection: {
      jsx: <EnvironmentProtection data={data} dataKey={dataKey} />,
    },
    homeMedia: {
      jsx: (
        <Media
          data={data}
          gs={
            data?.newStyle
              ? {
                  media_content_box_all: '!bg-white !pt-0',
                  titleBoxPadding: !data?.hideTitleBoxPadding
                    ? 'md:!pt-[72px] md:!pb-[32px]'
                    : '!pt-0',
                  title_box: '!bg-white',
                  media_layer: '!bg-white',
                  media_content_box_all_grid:
                    '!bg-[#F5F5F7] !py-[60px] xl:!py-[40px]',
                  media_content_all_grid: '!bg-[#F5F5F7] mt-6 md:mt-2',
                  subTitle:
                    '!text-center !text-[56px] !font-semibold !leading-[60px] !text-black md:!text-left xl:!text-[40px] xl:!leading-[48px]',
                  txtListNavItemBar: 'var(--solix-linear-color)',
                }
              : {
                  txtListNavItemBar: 'var(--solix-linear-color)',
                }
          }
        />
      ),
      fullWidth: true,
      noPadding: true,
    },
    newBlogs: {
      jsx: (
        <NewBlogs
          data={data}
          dataKey={dataKey}
          pageHandle={pagehandle}
          id={dataKey}
        />
      ),
    },
  }
}

const Component = ({
  data: initData,
  dataKey,
  pagehandle,
  metafields,
  product,
  variant,
  productComponents,
  relatedProducts,
}) => {
  // console.log("metafields", metafields)

  const { setRelativeLink } = useRelativeLink()
  if (!dataKey) return null

  // regular data
  const { key: componentKey, data: secData } = getComponentData(
    metafields,
    dataKey,
    variant,
    productComponents
  )

  const data = secData || initData

  // special data
  const shopData = {
    brandInfo: metafields.shop_brandRelated_info,
    series: metafields.shop_collections_series,
    brandRecommends: metafields.shop_brandRelated_recommends,
    judgemeSettings: metafields?.judgemeSettings,
    shopCommon: metafields.shopCommon,
  }

  const productData = {
    preRender: metafields.preRender,
    judgemeWidget: metafields.judgemeWidget,
  }

  const component = components({
    data,
    product,
    shopData,
    productData,
    dataKey,
    variant,
    relatedProducts,
    metafields,
    pagehandle,
    setRelativeLink,
  })[componentKey]

  /**
   * 根据诸多条件获得 Padding 值, 优先级如下
   * metafields 配置 > 组件默认配置 > 相同背景色 padding 减半 > 默认 padding
   * */
  const getPadding = (paddingVariant, defaultPadding = '5rem') => {
    const { component_order } = metafields
    const order = component_order?.indexOf(dataKey)
    // 配置优先
    if (data?.styles && data?.styles[paddingVariant]) {
      return data?.styles[paddingVariant]
    }
    // 代码默认配置
    else if (component?.noPadding) {
      return 0
    } else if (component[paddingVariant] !== undefined) {
      return component[paddingVariant]
    }
    // 没有 component_order 的情况跳过 padding 处理
    else if (!component_order) {
      return defaultPadding
    }
    // // 如果不是最后一个且后一个 component 的背景色跟自己相同，paddingBottom 减半
    // else if (
    //   paddingVariant === 'paddingBottom' &&
    //   dataKey !== component_order[component_order.length - 1]
    // ) {
    //   // 用此方法是为了避免存在 variant 的 component
    //   const { key: componentKeyNext, data: dataNext } = getComponentData(
    //     metafields,
    //     component_order[order + 1],
    //     variant
    //   )
    //   if (
    //     // 先确认下一个 component 没有配置 padding 且没有设置为 noPadding
    //     !dataNext?.styles?.paddingBottom &&
    //     !components({})[componentKeyNext]?.noPadding &&
    //     data?.styles?.backgroundColor === dataNext?.styles?.backgroundColor
    //   )
    //     return cssVal(`${defaultPadding}/2`)
    // }
    // // 如果不是第一个，且前一个 component 背景色跟自己相同, paddingTop 减半
    // else if (
    //   paddingVariant === 'paddingTop' &&
    //   dataKey !== component_order[0]
    // ) {
    //   // 用此方法是为了避免存在 variant 的 component
    //   const { key: componentKeyLast, data: dataLast } = getComponentData(
    //     metafields,
    //     component_order[order - 1],
    //     variant
    //   )
    //   if (
    //     // 先确认前一个 component 没有配置 padding 且没有设置为 noPadding
    //     !dataLast?.styles?.paddingTop &&
    //     !components({})[componentKeyLast]?.noPadding &&
    //     data?.styles?.backgroundColor === dataLast?.styles?.backgroundColor
    //   )
    //     return cssVal(`${defaultPadding}/2`)
    // }
    else {
      return defaultPadding
    }
  }
  // 默认移动端配置
  let mobileSettings = { paddingBottom: '2.5rem', paddingTop: '2.5rem' }
  if (component?.noPadding) {
    mobileSettings = {
      paddingBottom: '0px',
      paddingTop: '0px',
    }
  }

  if (
    !component?.jsx ||
    // 除了数据来自 shop 或者组件不需要 data 的情况，如果传入的数据为空，则返回 null
    (!component?.dataFromShop && !component?.noNeedData && isObjEmpty(data))
  ) {
    return null
  }
  if (component.noWrap) {
    return component?.jsx
  }
  return (
    <SectionLayout
      id={dataKey}
      key={dataKey}
      fullWidth={
        data?.styles?.fullWidth != undefined
          ? data?.styles.fullWidth
          : component?.fullWidth
      }
      paddingTop={getPadding('paddingTop')}
      paddingBottom={getPadding('paddingBottom')}
      bgColor={data?.styles?.backgroundColor}
      className={component?.className}
      responsive={
        data?.styles?.responsive ||
        component.responsive?.map((r) => ({
          breakpoint: r.breakpoint,
          settings: {
            ...mobileSettings,
            ...r.settings,
          },
        })) || [
          {
            breakpoint: 769,
            settings: {
              ...mobileSettings,
            },
          },
        ]
      }
    >
      {component?.jsx}
    </SectionLayout>
  )
}

export default Component
